// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx';

// Imports => Constants
import { FILE_TYPES, KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcIsUndefined,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
  AcDownloadFile,
} from '@utils';
import dayjs from 'dayjs';

const _default = {
  options: {},
  projects: null,
  project: null,
  project_options: null,
  piling_reports: null,
};

let app = {};

export class ProjectsStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.DASHBOARD_PROJECTS);
    // AcAutoLoad(this, KEYS.PROJECTS);
    // AcAutoLoad(this, KEYS.PROJECT);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  projects = null;

  @observable
  project = null;

  @observable
  project_options = null;

  @observable
  piling_reports = null;

  @computed
  get current_projects() {
    return this.projects;
  }

  @computed
  get current_project() {
    return this.project;
  }

  @computed
  get current_project_options() {
    return this.project_options;
  }

  @computed
  get current_projects_list() {
    if (!this.current_projects || !this.current_projects.data) return null;

    // const result = new AcFormatRawDataAsList(this.current_projects.data, {
    //  ID: 'id',
    //  NAME: 'name',
    // });

    return this.current_projects.data;
  }

  @computed
  get current_piling_reports() {
    return this.piling_reports;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.projects
      .list(params)
      .then(async (response) => {
        await this.set(KEYS.PROJECTS, response, true);

        if (response.meta && response.meta.current_page)
          this.setPageNumber(response.meta.current_page);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve projects',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    const params = { v2: true, include_index: true };

    return app.store.api.projects
      .get_by_id(id, params)
      .then(async (response) => {
        await this.set(KEYS.PROJECT, response.data, true);
        await this.set(KEYS.PROJECT_OPTIONS, response.projects, true);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        const code = AcFormatErrorCode(error);

        if (!axios.isCancel(error) && code !== 204) {
          let title = `Something has gone wrong while retreiving your project (id: <strong>${id}</strong>)`;

          if (id === 'primary')
            title = `Something has gone wrong while retreiving your project`;

          app.store.toasters.add({
            variant: 'error',
            title,
            description: AcFormatErrorMessage(error),
          });
        }

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  store = (data) => {
    this.setBusy(true);

    const params = { v2: true };

    return app.store.api.projects
      .store(data, params)
      .then(async (response) => {
        await this.set(KEYS.PROJECT, response, true);

        app.store.toasters.add({
          variant: 'success',
          title: `Project <strong>${response.name}</strong> has been added.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to store project`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  restore_reports = async (selection) => {
    console.log('selection', selection);

    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'pending',
      title: 'Restoring reports',
      description: `Restoring selected reports`,
      indeterminate: true,
    });

    try {
      const res = await app.store.api.projects.restore_reports(
        this.current_project.id,
        {
          hashes: selection,
        }
      );

      await app.store.toasters.update(toast.id, {
        title: 'Report restored',
        description: `${selection.length} report${
          selection.length === 1 ? '' : 's'
        } have been restored`,
        variant: 'success',
        indeterminate: false,
      });
      return res;
    } catch (error) {
      app.store.toasters.update(toast.id, {
        title: 'Restoring report failed',
        description: `Selected reports could not be restored`,
        code: AcFormatErrorCode(error),
        variant: 'error',
        indeterminate: false,
      });
    } finally {
      this.setBusy(false);
    }
  };

  @action
  download_reports = async (selection) => {
    let extension = 'zip';
    let mime = 'application/octet-stream';

    const filename = `${this.project.name}_${dayjs()}.${extension}`;

    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `File <strong>${filename}</strong> has been requested`,
      indeterminate: true,
    });

    try {
      const res = await app.store.api.projects.download_pile_selection(
        this.project.id,
        selection
      );

      window.open(res.url);

      app.store.toasters.update(toast.id, {
        title: 'Download is complete',
        description: `File <strong>${filename}</strong> has been downloaded`,
        variant: 'success',
        indeterminate: false,
      });
      return;
    } catch (error) {
      app.store.toasters.update(toast.id, {
        title: null,
        description: `Failed to download file <strong>${filename}</strong>`,
        code: AcFormatErrorCode(error),
        variant: 'error',
        indeterminate: false,
      });
    }
  };

  @action
  update = (id, data) => {
    this.setBusy(true);

    const params = { v2: true, include_index: true };

    return app.store.api.projects
      .update(id, data, params)
      .then(async (response) => {
        await this.set(KEYS.PROJECT, response.data, true);
        await this.set(KEYS.PROJECT_OPTIONS, response.projects, true);

        app.store.toasters.add({
          variant: 'success',
          description: `Project details saved successfully.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update project (id: ${id})`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update_location = (id, data) => {
    this.setBusy(true);

    const params = { v2: true, include_index: true };

    return app.store.api.projects
      .update(id, data, params)
      .then(async (response) => {
        await this.set(KEYS.PROJECT, response.data, true);
        await this.set(KEYS.PROJECT_OPTIONS, response.projects, true);

        app.store.toasters.add({
          variant: 'success',
          description: `Project location saved successfully.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update project location`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.projects
      .delete(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          title: `Project <strong>${name}</strong> deleted`,
        });

        await this.list();

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete project <strong>${name}</strong>`,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  add_configurations = (id, data) => {
    this.setBusy(true);

    const params = { v2: true };

    return app.store.api.projects
      .add_configurations(
        id,
        {
          configurations: data,
        },
        params
      )
      .then(async (response) => {
        await this.set(KEYS.PROJECT, response, true);

        const description =
          data.length === 1
            ? 'Selected configuration has been added'
            : 'Selected configurations have been added';

        app.store.toasters.add({
          variant: 'success',
          description,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        const title =
          data.length === 1
            ? 'Failed to add the selected configuration'
            : 'Failed to add the selected configurations';

        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);
      });
  };

  @action
  remove_configuration = (project_id, { id, name }) => {
    this.setBusy(true);

    const params = { v2: true };

    return app.store.api.projects
      .remove_configurations(project_id, { configurations: [id] }, params)
      .then(async (response) => {
        await this.set(KEYS.PROJECT, response, true);

        app.store.toasters.add({
          variant: 'success',
          title: `Configuration <strong>${name}</strong> is removed.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to remove configuration <strong>${name}</strong> from project`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  remove_operator = ({ id: project_id, name: project_name }, { id, name }) => {
    this.setBusy(true);

    const params = { v2: true };

    return app.store.api.projects
      .remove_operator(project_id, { users: [id] }, params)
      .then(async (response) => {
        await this.set(KEYS.PROJECT, response, true);

        app.store.toasters.add({
          variant: 'success',
          title: `Operator <strong>${name}</strong> is unassigned from project <strong>${project_name}</strong>.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to unassign operator <strong>${name}</strong> from project <strong>${project_name}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  remove_report = async (pile_id) => {
    this.setBusy(true);

    try {
      const res = await app.store.api.projects.remove_report(
        this.current_project.id,
        pile_id
      );
      await app.store.toasters.add({
        variant: 'success',
        title: `Report <strong>${pile_id}</strong> deleted`,
      });
      this.setBusy(false);
      return Promise.resolve(res);
    } catch (error) {
      this.setBusy(false);

      if (!axios.isCancel(error)) throw error;
      return Promise.reject(error);
    }
  };

  @action
  upload_pile_list = async (project_id, data, filename) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'upload',
      title: 'Preparing upload',
      description: `Pile list is being uploaded`,
      indeterminate: true,
    });

    const params = { v2: true };

    return app.store.api.projects
      .upload_pile_list(project_id, data, params)
      .then(async (response) => {
        await this.get_by_id(project_id);

        app.store.toasters.update(toast.id, {
          title: 'Upload is complete',
          description: `Pile list <strong>${filename}</strong> has been uploaded and sent to control unit`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.update(toast.id, {
            variant: 'error',
            title: `Failed to upload pile list <strong>${filename}</strong>`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
            indeterminate: false,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  download_pile_list = async ({ link, file_name }) => {
    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `Pile list file <strong>${file_name}</strong> has been requested`,
      indeterminate: true,
    });

    return app.store.api.projects
      .download_pile_list(link)
      .then(async (response) => {
        const { headers, data } = response;

        const disposition = headers['content-disposition'];

        if (disposition && disposition.indexOf('attachment') !== -1) {
          var pattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = pattern.exec(disposition);
          if (matches != null && matches[1]) {
            file_name = matches[1].replace(/['"]/g, '');
          }
        }

        await AcDownloadFile(response, file_name, FILE_TYPES.LST);

        app.store.toasters.update(toast.id, {
          title: 'Download is complete',
          description: `Pile list file <strong>${file_name}</strong> has been downloaded`,
          variant: 'success',
          indeterminate: false,
        });

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download Pile list file <strong>${file_name}</strong>`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  list_reports = (project_id, params) => {
    this.setBusy(true);

    return app.store.api.projects
      .list_reports(project_id, params)
      .then(async (response) => {
        await this.set(KEYS.PILING_REPORTS, response, true);

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve piling reports for configuration (id: <strong>${params.configuration_id}</strong>) in project (id: <strong>${project_id}</strong>)`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default ProjectsStore;
